import React from "react"
import Container from "src/components/container"
import Service from "src/components/section/service/index"
import SEO from "src/components/seo"

const Desc = () => (
  <>
    IT-аутсорсинг, сопровождение и консалтинг для проектов любой сложности.
    <br /> <br />
    <strong>Что мы предлагаем?</strong>
    <p>
      Наша команда предоставляет полный цикл разработки ПО от идеи до
      тестирования безопасности, внедрения и сопровождения.
    </p>
    <strong>Наши услуги</strong>
    <ul>
      <li>Создаем отдельные команды разработки под ваши проекты</li>
      <li>Разрабатываем высоконагруженные приложения и сервисы</li>
      <li>Осуществляем мониторинг и поддержку в режиме 24х7</li>
      <li>Улучшаем существующие ИТ-продукты и отдельные их узлы</li>
    </ul>
    <p>
      Технологии Универсального стека не существует. Мы постоянно следим за
      новинками и используем инструменты, которые совершенствуются мировым
      сообществом. Наша команда раскладывает каждый проект на составляющие, а
      затем использует наиболее современные практики для решения конкретных
      задач. В результате вы получаете гибкие решения, которые дешевле внедрять
      и легче поддерживать.
    </p>
    <strong>Наша экспертиза</strong>
    <ul>
      <li>
        Разработка сервисов процессинга платежей с высокой пропускной
        способностью
      </li>
      <li>Разработка публичных и служебных API</li>
      <li>Разработка высоконагруженных приложений и веб-сервисов</li>
      <li>Разработка кабинетов для клиентов и администраторских интерфейсов</li>
      <li>
        Создание дашбордов для постоянного мониторинга изменений технических и
        бизнес-метрик
      </li>
    </ul>
    <strong>Почему мы?</strong>
    <ul>
      <li>
        Создаем предпосылки для быстрого роста бизнеса заказчика, гибко
        масштабируя процессы
      </li>
      <li>
        Обеспечиваем полный цикл разработки — от идеи проекта до сопровождения и
        развития
      </li>
      <li>
        Обладаем 10-летним опытом безопасной разработки высоконагруженных
        приложений и сервисов
      </li>
    </ul>
  </>
)

export default function Develop() {
  return (
    <Container>
      <SEO
        title="Разработка программного обеспечения"
        description="Мы — команда back-end и front-end разработчиков, DevOps-инженеров, AppSec и Infosec-специалистов со стажем более 10 лет и страстью к перфекционизму."
      />
      <section className="service h100">
        <div className="container">
          <Service
            title="Разработка надёжного ПО"
            desc={<Desc />}
            feedbackForm
          />
        </div>
      </section>
    </Container>
  )
}
